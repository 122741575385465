import { Link } from 'react-router-dom';

import classes from './MainNavigation.module.css';

import logo from './images/logo.png';
import smart from './images/smart.png';

function MainNavigation() {
    return (
        <div>
            <header className={classes.header}>
                {/* Navigation */}
                <nav>
                    <Link to='/'><img src={logo} className={classes.logo} alt="logo" /></Link>

                    <Link to='/'><img src={smart} className={classes.smart} alt="smart" /></Link>
                </nav>
            </header>
        </div>
    )
}

export default MainNavigation;
// import { Link } from 'react-router-dom';

import classes from './Banner.module.css';


import person from '../components/layout/images/human.png';

function HumanPage() {
  return (
    <div>
      {/* <Link to='/'><img src={person} className={classes.banner} alt="logo" /></Link> */}
      <img src={person} className={classes.banner} alt="logo" />
      <div className='card'>
        <h1>
          De menselijke kant van IT
          <br></br>
        </h1>
        <p>
          Vaak wordt de impact die veranderingen op mensen hebben ernstig onderschat, wat ervoor kan zorgen dat niet alleen onvoldoende gebruik wordt gemaakt van nieuwe mogelijkheden maar de adoptie van nieuwe technologie zelfs volledig in het water valt.
          <br></br>
          <br></br>
          Duidelijke communicatie naar en persoonlijke begeleiding van uw medewerkers zijn van doorslaggevend belang om draagvlak te creëren voor een fundamenteel andere manier van werken.
          <br></br>
          <br></br>
          Het inventariseren van de bereidheid van uw medewerkers om te veranderen is cruciaal evenals het onder de aandacht brengen van de voordelen die door de wijzigingen duidelijk merkbaar zullen zijn voor verschillende groepen medewerkers.
        </p>
        <body>
        </body>
      </div>
      <div className='card'>
        <h1>
          Een uniek programma voor gebruikersadoptie
          <br></br>
        </h1>
        <p>
          Vincent365 heeft een uniek programma ontwikkeld waarmee uw gehele organisatie de aandacht krijgt die nodig is om op een goede manier met de Microsoft 365 Moderne Werkplek te leren werken.
          <br></br>
          <br></br>
          Het programma voorziet in uitgebreide trainingsmaterialen, trainingssessies op basis van het train-the-trainer principe, biedt ruimte voor vragen en vermindert de druk op de IT-afdeling bij de ondersteuning van eindgebruikers.
          <br></br>
          <br></br>
          Dit alles op een super-efficiënte manier die 100% past bij de Microsoft 365 Moderne Werkplek.
          <br></br>
          <br></br>
          Uitstekende referenties zijn op aanvraag beschikbaar.
        </p>
        <body>
        </body>
      </div>
    </div>
  );
}

export default HumanPage;
// import { Link } from 'react-router-dom';

import classes from './Banner.module.css';


import techknow from '../components/layout/images/technical.png';

function TechnicalPage() {
    return (
         <div>
      {/* <Link to='/'><img src={techknow} className={classes.banner} alt="logo" /></Link> */}
      <img src={techknow} className={classes.banner} alt="logo" />
      <div className='card'>
                <h1>
                    Technische ondersteuning van uw IT-afdeling
                    <br></br>
                </h1>
                <p>
                    Met uitsluitend succesvol uitgevoerde projecten in enterprise omgevingen, in de financiële sector en bij de overheid heeft Vincent365 de kennis en de ervaring om te ondersteunen bij de meest complexe vraagstukken en/of uw IT-personeel te trainen voor het uitvoeren van de migratie naar en het beheer van Microsoft 365.
                    <br></br>
                    <br></br>
                    Uitstekende referenties zijn op aanvraag beschikbaar.
               </p>
                <body>
                </body>
            </div>
      </div>
    );
  }
  
  export default TechnicalPage;
// import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainNavigation from "./components/layout/MainNavigation";
import Navbar from "./components/layout/Navbar";
import MainFooter from "./components/MainFooter";
import HomePage from './pages/Home';
import StrategyPage from "./pages/Strategy";
import TechnicalPage from "./pages/Technical";
import HumanPage from "./pages/Human";
// import ContactPage from "./pages/Contact";


function App() {
  return (
       <div>
<MainNavigation />
<Navbar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/strategy' caseSensitive={false} element={<StrategyPage />} /> 
        <Route path='/technical' caseSensitive={false} element={<TechnicalPage />} /> 
        <Route path='/human' caseSensitive={false} element={<HumanPage />} /> 
        {/* <Route path='/contact' caseSensitive={false} element={<ContactPage />} />  */}
      </Routes>
<MainFooter />
    </div>
  );
}

export default App;

import { Link } from 'react-router-dom';

import classes from './Navbar.module.css';

function Navbar() {
    return (
        <div>


            <header className={classes.navi}>



                <nav>

                    <ul>
                    <li> <Link to='/strategy'>
                            Strategie
                       </Link> </li>
                        <li><Link to='/technical'>
                            Techniek
                        </Link></li>
                        <li>
                            <Link to='/human'>Mensen</Link>
                        </li>
                        {/* <li>
                            <Link to='/contact'>Contact</Link> 
                        </li> */}

                    </ul>

                </nav>

            </header>
        </div>
    )
}

export default Navbar;
import classes from './layout/MainNavigation.module.css';

function MainFooter() {
    return (
         <div>
             
            
        <header className={classes.footer}>
               Vincent365 | Koninginneweg 58A, 1241 CV  Kortenhoef | info@vincent365.nl | 06-21989980 
               <br></br>
               Kvk 84967110 | Btw NL004046180B96

        </header>
            </div>
    )
}

export default MainFooter;
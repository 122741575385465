// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banner_banner__Wo5v6 {\r\n    width: 100%;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/Banner.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf","sourcesContent":[".banner {\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "Banner_banner__Wo5v6"
};
export default ___CSS_LOADER_EXPORT___;

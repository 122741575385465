import { Link } from 'react-router-dom';

import classes from './Banner.module.css';

// import modernworkplace from '../components/layout/images/moderworkplace.png';
import modernworkplace from '../components/layout/images/mwp-4.png';

function HomePage() {
    return (
        <div>
            <Link to='/'><img src={modernworkplace} className={classes.banner} alt="logo" /></Link>
            <div className='card'>
                <h1>
                    Learning it the hard way?
                    <br></br>
                </h1>
                <p>
                    Veel bedrijven nemen vroeg of laat het besluit om in de Cloud te gaan werken.
                    Vervolgens worden laptops en licenties aangeschaft in de verwachting dat alles gewoon werkt maar meestal wordt daarna al snel een externe partij ingehuurd omdat er naast apparaten en licenties ook veel kennis nodig is om de transitie naar de Moderne Werkplek succesvol te laten verlopen.<br></br><br></br>
                    Zonder strategische doelstellingen en een duidelijk beschreven informatiebeleid kan een externe partij echt niet eventjes snel alle problemen oplossen en ervoor zorgen dat uw data veilig is in de Cloud. Dit geldt zeker wanneer de eigen IT-afdeling onvoldoende kennis heeft en 
                    er helemaal niets voor voelt om over te stappen naar een moderne manier van werken. Ook door andere factoren kan het lang duren om de situatie te herstellen als de migratie naar de Cloud zonder goede voorbereiding is uitgevoerd. 
                    Bijvoorbeeld wanneer aangeschafte licenties niet de functionaliteiten bieden die u nodig heeft en er geen budget beschikbaar is voor de juiste licenties of wanneer gebruikers geen idee hebben hoe ze om moeten gaan met de nieuwe manier van werken.
                    <br></br>
                    <br></br>
                    Ondertussen staan uw bedrijfsgegevens al in de Cloud, heeft u geen idee wie er toegang heeft tot welke data, stapelen de incidenten zich op en neemt de frustratie bij uw medewerkers maar ook bij uzelf alleen maar toe.
                </p>
                <body>
                </body>
            </div>
            <div className='card'>
                <h1>
                    Doing IT the smart way
                    <br></br>
                </h1>
                <p>
                    Het besluit om in de Cloud te gaan werken is een prima besluit en het kan ook heel anders lopen als eerst de juiste kennis in huis wordt gehaald om te ondersteunen bij de stappen die moeten worden doorlopen voordat de Microsoft 365 omgeving voor gebruikers toegankelijk wordt gemaakt en uw bedrijfsdata naar de Cloud wordt gemigreerd.
                </p>
                <body>
                </body>
            </div>
            <div className='card'>
                <h1>
                    Vincent365 maakt het verschil
                    <br></br>
                </h1>
                <p>
                    Met duidelijk advies over de risico's van werken in de Cloud, hoe u Microsoft 365 kunt gebruiken om uw gegevens te beschermen terwijl uw medewerkers juist makkelijker kunnen werken en het beheer kan worden vereenvoudigd. 
                    <br></br>
                    <br></br>
                    Een duidelijk stappenplan om de kosten te beperken terwijl uw bedrijf geleidelijk de transitie naar de Microsoft 365 Modern Workplace doormaakt.
                    <br></br>
                    <br></br>
                    Persoonlijke begeleiding van uw eindgebruikers zodat de impact van de wijzigingen een positieve impuls geeft aan de productiviteit van uw medewerkers waardoor zij zullen ervaren dat deze wijzigingen het werk juist makkelijker maken.
                </p>
                <body>
                </body>
            </div>
        </div>

    );
}

export default HomePage;
// import { Link } from 'react-router-dom';

import classes from './Banner.module.css';


import cloudstrat from '../components/layout/images/cloudroadmap.png';

function StrategyPage() {
    return (
         <div>
      {/* <Link to='/'><img src={cloudstrat} className={classes.banner} alt="logo" /></Link> */}
      <img src={cloudstrat} className={classes.banner} alt="logo" />
      <div className='card'>
                <h1>
                    Vooruitdenken
                    <br></br>
                </h1>
                <p>
                    Aan de hand van een duidelijke roadmap kunnen wij bepalen waar uw organisatie zich bevind in de transitie naar de Cloud en welke fasen nog moeten worden doorlopen om te komen tot het volwassenheidsniveau dat hoort bij uw strategische doelstellingen.
                    <br></br>
                    <br></br>
                    Vervolgens maken we samen een plan van aanpak zodat u een helder beeld krijgt van de benodigde inzet, extra functionaliteit die beschikbaar komt en de bijbehorende kosten wanneer u de volgende stap zet in de transitie naar de Microsoft 365 Moderne Werkplek.   
                    <br></br>
                    <br></br>
                    Zo weet u precies wanneer u het punt heeft bereikt waarop aan alle doelstellingen is voldaan voordat u daadwerkelijk uw bedrijfsgegevens veilig aan de Cloud kunt toevertrouwen.
                    <br></br>
                    <br></br>
                    Uitstekende referenties zijn op aanvraag beschikbaar.
                </p>
                <body>
                </body>
            </div>
      </div>
    );
  }
  
  export default StrategyPage;
  